export enum Route {
  HOME = '/',
  OVERALL = '/overall',
  PARTICIPATION = '/participation',
  COMPETITION = '/competition',
  REPRESENTATION = '/representation',
  POLARIZATION = '/polarization',
  NOT_FOUND = '/404',
  ABOUT = '/about-us',
  READ_THE_REPORT = '/read-the-report',
  MEDIA = '/media',
  METHODOLOGY = '/methodology',
  TAKE_ACTION = '/take-action',
  PRIVACY_POLICY = '/privacy-policy',
  CONTACT = '/contact',
  STATE_OF_REFORM = '/state-of-reform',
  DATA = 'https://stateofdemocracy.com',
}
